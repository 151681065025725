<template>
  <v-container fill-height fluid class="x-ctn" id="vcont">
    <v-row align="center" justify="center">
      <v-card
          class="mx-auto pa-3 pt-4 pa-sm-10 pt-sm-8"
          width="500"
      >
        <v-img
            max-width="290"
            src="@/assets/963_logotipo.webp"
        ></v-img>
        <div class="title font-weight-regular pt-4">Olá! Vamos Começar</div>
        <div class="subtitle-1 font-weight-light pb-5">Faça login para continuar.</div>
        <v-form
            ref="form"
            v-on:submit.prevent
            :disabled="formDisabled"
        >
          <v-text-field
              class="my_field_per"
              type="email"
              autocomplete="email"
              ref="login"
              outlined
              required
              validate-on-blur
              label="E-Mail"
              prepend-inner-icon="mdi-account"
              v-model="form.username"
              :rules="[rules.required(form.username,'O E-Mail deve ser preenchido.'), rules.emailValidate(form.username)]"
              v-on:keyup.enter="onEnter('login')"
          />

          <v-text-field
              class="my_field_per"
              outlined
              required
              ref="senha"
              label="Senha"
              prepend-inner-icon="mdi-lock"
              :type="show1 ? 'text' : 'password'"
              v-model="form.password"
              :rules="[rules.required(form.password,'A SENHA deve ser informada.'), rules.minchars(form.password,4, 'A SENHA deve ter um mínimo de 4 caracteres.')]"
              @click:append="show1 = !show1"
              v-on:keyup.enter="onEnter('senha')"
          >
            <template v-slot:append>
              <v-icon tabindex="-1" @click="show1 = !show1" style="cursor: pointer;">
                {{ show1 ? 'mdi-eye' : 'mdi-eye-off' }}
              </v-icon>
            </template>
          </v-text-field>

          <v-alert
              v-if="alert.visible"
              type="error"
              class="mb-7"
          >
            <span v-html="alert.msg"></span>
          </v-alert>

          <v-btn
              ref="btnsubmit"
              class="white--text"
              :loading="loading"
              block
              color="red darken-1"
              x-large
              type="button"
              @click="validateBeforeSubmit"
              :disabled="loginButtonDisabled"
          >Entrar
          </v-btn>

        </v-form>
        <v-overlay
            :absolute="true"
            :value="loginLoading"
            :opacity="0.2"
        >
          <v-progress-circular indeterminate :size="80" color="primary" class=""></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import {mapMutations} from "vuex";
import axios from "axios";
import {ENTRYPOINT} from "../config/entryPointApi";

export default {
  data() {
    return {
      show1: false,
      show2: true,
      show3: false,
      show4: false,
      loginLoading: false,
      loginButtonDisabled: false,
      formDisabled: false,
      alert: {
        visible: false,
        msg: ''
      },
      password: 'Password',
      form: {
        username: null,
        password: null,
      },
      errorMessages: {},
      loading: false,
      rules: {
        minchars: (value, minQtdChars, campo) => {
          return (value !== null && (value.length > 0 && value.length < minQtdChars)) ? campo : true;
        },
        required: (value, campo) => {
          return (value === null || value.trim() === '') ? campo : true;
        },
        emailValidate: (value) => {
          const emailValidatePattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return emailValidatePattern.test(value) ? true : 'O E-Mail informado esta inválido.';
        }
      },
      vueExLocalState: {
        user_email: this.$store.state.user_email,
        user_token: this.$store.state.user_token,
      }
    };
  },
  methods: {
    ...mapMutations([
      'setStateUserEmail',
      'setStateUserToken',
      'setStateUserName'
    ]),
    textFieldfocus(input) {
      switch (input) {
        case 'email':
          if (this.alert.visible) {
            this.alert.visible = !this.alert.visible;
          }
          break;
        case 'senha':
          if (this.alert.visible) {
            this.alert.visible = !this.alert.visible;
          }
          break;
        default:
          break;
      }
    },
    axiosApiLogin() {
      let params = {
        email: this.form.username,
        password: this.form.password
      }
      axios({
        url: ENTRYPOINT + '/login',
        method: 'post',
        params: params
      }).then((response) => {
        let retorno = response.data.response;
        if (!retorno.success) { // Quando há algum retorno com falha
          this.alert.msg = retorno.message;
          this.alert.visible = true;
          this.preloade(false);
          if (retorno.error_code === 234) { // Erro de senha inválida
            this.$nextTick(() => {
              this.$refs.senha.focus();
            });
          }
          if (retorno.error_code === 233) { // Erro E-Mail inválido
            this.$nextTick(() => {
              this.$refs.login.focus();
            });
          }
        } else { // Quando usuário e senha estão corretos, registra token no storage e manda usuário para página de usuário logado
          this.setStateUserEmail(retorno.data.user_email);
          this.setStateUserToken(retorno.data.user_token);
          this.setStateUserName(retorno.data.user_name);
          this.$router.push({name: 'Menu'});
        }
        console.log(retorno);
      }).catch(error => {
        let erro = error.toJSON();
        let errorMessage = erro.message;
        console.log(errorMessage);
        this.preloade(false);
      });
    },
    onEnter: function (local) { // Quando a tecla enter é pressionada em determinado textField do formulário
      switch (local) {
        case 'login':
          this.$refs.senha.focus();
          break;
        case 'senha':
          this.validateBeforeSubmit();
          break;
        default:
          break;
      }
    },
    validateBeforeSubmit() {
      if (this.loading === true) {
        return;
      }
      if (this.$refs.form.validate()) { // Caso todas validações do front estejam OK
        this.preloade(true);
        this.axiosApiLogin();
      }
    },
    preloade(status) {
      if (status === true) {
        this.formDisabled = true
        this.loginButtonDisabled = true;
        this.loading = true;
        this.loginLoading = true;
      } else {
        this.formDisabled = false
        this.loginButtonDisabled = false;
        this.loading = false;
        this.loginLoading = false;
      }
    }
  },
  mounted: function () {
    // this.$router.push({ name: 'About' });
    setTimeout(() => {
      // this.$refs.login.errormessages = ['asdasd'];
      // this.$refs.login.error = true;
    }, 2000);
  },
  created() {
    document.title = 'Login Admin - Apostilas Domínio';
    // console.table(this.vueExState)
  }
};
</script>
<style type="text/css">
.my_field_per .v-messages__message {
  line-height: unset;
}
.my_field_per .v-messages {
  font-size: unset;
  /*min-height: 24px;*/
}

#vcont {
  background-color: #f6f6f6;
}
</style>