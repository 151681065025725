import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user_email: null,
    user_token: null,
    user_name: null
  },
  mutations: {
    setStateUserEmail (state, value) {
      state.user_email = value;
    },
    setStateUserToken (state, value) {
      state.user_token = value;
    },
    setStateUserName (state, value) {
      state.user_name = value;
    },
    logOut (state) {
      state.user_token = null;
      state.user_email = null;
      state.user_name = null;
    }
  },
  actions: {
  },
  modules: {
  }
})
