import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/menu',
        name: 'Menu',
        component: () => import('../views/Menu.vue')
    },
    {
        path: '/apostilas',
        name: 'Apostilas',
        component: () => import('../views/Apostilas.vue')
    }
]

const router = new VueRouter({
    //base: '/admin',
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {

    if (to.name === 'Login') { // Verifica se a rota está entrando em Login
        if (store.state.user_email !== null) { // Verifica se usuário está logado, se sim, manda para página de usuário logado
            next('menu');
        } else { // Caso contrário segue para página de Login
            next();
        }
    } else { // Caso contrário segue com qualquer outra rota
        if (store.state.user_email !== null) { // Verifica se usuário está logado, se sim, deixa usuário seguir para a rota desejada
            next();
        } else { // Caso usuário não esteja logado, manda ele para página de login
            next('/');
        }
    }

})

export default router
