<template>
  <div>
    <v-card
        class="pa-0 pa-sm-5 fill-height"
        color="blue-grey lighten-5"
        tile
    >

      <Topo
          title="Anexar PDF´s em Apostilas."
          :retornar="true"
      >
      </Topo>
    </v-card>

    <v-card
        class="pa-0 pa-sm-5 mb-16 fill-height"
        color="blue-grey lighten-5"
        tile
    >
      <v-card-title class="pa-0 mb-2 mb-sm-4">
        <v-col
            cols="12"
            sm="12"
            md="6"
            lg="4"
        >
          <v-text-field
              class="col-4"
              v-model="dataTable.searchApostila"
              prepend-icon="mdi-magnify"
              label="Buscar: Apostila, SKU, ID etc."
              solo
              flat
              hide-details
              clearable
          />
        </v-col>
        <v-spacer></v-spacer>
        <v-col
            class="d-flex justify-end"
            cols="12"
            sm="12"
            md="6"
            lg="4"
        >
          <v-checkbox
              v-model="somentePDF.checkbox"
              label="Somente Apostilas com PDF"
              @click="clickCheckBoxSomenteComPDF"
          />
        </v-col>
      </v-card-title>
      <v-data-table
          :headers="dataTable.headers"
          :items="dataTable.data"
          :items-per-page="30"
          class="elevation-1"
          :loading="dataTable.loading"
          loading-text="Carregando Aguarde..."
          :search="dataTable.searchApostila"
          :header-props="{
            'sort-by-text': 'Ordenar Por'
          }"
          :footer-props="{
            'items-per-page-options' : [
              5, 10, 30, -1
            ],
            'items-per-page-all-text': 'Todas',
            'items-per-page-text': 'Linhas por Página'
          }"
      >
        <template v-slot:item="{item}">
          <tr
              :ref="'linha' + item.id"
              :class="item.available === 'true' ? '' : 'red lighten-3'"
          >
            <td class="text-start">{{ item.id }}</td>
            <td class="text-start">{{ item.id_webstore }}</td>
            <td class="text-start">{{ item.sku }}</td>
            <td class="text-start">{{ item.title }}</td>
            <td class="text-center">
              <v-icon
                  v-show="item.with_pdf_attached === 'yes'"
                  size="25px"
              >
                mdi-file-pdf-box
              </v-icon>
            </td>
            <td class="text-start">{{ item.available === 'true' ? 'SIM' : 'NÃO' }}</td>
            <td class="text-center">
              <v-icon
                  size="25px"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div style="height: 30px;">

      </div>
    </v-card>
    <v-dialog
        content-class="ma-2"
        persistent
        v-model="dataTable.dialogEdit.visible"
        transition="dialog-top-transition"
        max-width="600"
    >
      <template v-slot:default>
        <v-card>
          <v-toolbar
              class="mb-4 my_toolbar py-3"
              color="primary"
              flat
              dark
          >
            <span v-html="dataTable.dialogEdit.textTop"/>
            <v-spacer></v-spacer>
            <v-icon
                class="ml-3"
                @click="closeDialog"
            >
              mdi-close-thick
            </v-icon>
          </v-toolbar>
          <v-card-text class="align-center d-flex flex-column flex-sm-row justify-space-around">
            <v-img
                v-if="dataTable.dialogEdit.vimg"
                class="align-self-stretch grey lighten-2 my_img"
                :src="dataTable.photoApostila"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <Upload
                v-show="!textPdf.visible"
                id="upload_control_id"
                ref="uploadControl"
                :class="['mt-8 mt-sm-0 ' + upload.classAdd]"
                drag
                :action="upload.action"
                :limit="1"
                :headers="upload.headers"
                :on-progress="uploadOnProgress"
                :on-success="uploadComplete"
                :on-remove="uploadRemove"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Arraste o PDF ou <em>Click para Upload</em></div>
            </Upload>

            <v-col
                v-show="textPdf.visible"
                sm="7">
              <v-text-field
                  hide-details
                  class="mt-4 mt-sm-0 mb-3"
                  outlined
                  readonly
                  :value="textPdf.text"
              />

              <v-btn
                  :loading="downloadBtn.loading"
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="clickDownloadBtn"
              >
                Download
                <v-icon
                    right
                    dark
                >
                  mdi-cloud-download
                </v-icon>
              </v-btn>

              <v-btn
                  :loading="detachPdf.loading"
                  :disabled="false"
                  color="error"
                  class="ma-2 white--text"
                  @click="clickDetachPdf"
              >
                Desvincular PDF
                <v-icon
                    right
                    dark
                >
                  mdi-delete-circle
                </v-icon>
              </v-btn>

            </v-col>

          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                text
                @click="closeDialog"
            >Fechar
            </v-btn>
          </v-card-actions>

          <v-overlay
              color="white"
              opacity="1"
              :absolute="true"
              :value="dialog.loading"
          >
            <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
            ></v-progress-circular>
          </v-overlay>

        </v-card>
      </template>
    </v-dialog>


    <div class="text-center">
      <v-dialog
          v-model="alertFileSize.show"
          width="500"
          @close="closeDialogAlertFileSize"
          @click:outside="closeDialogAlertFileSize"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Falha no Envio do PDF
          </v-card-title>

          <v-card-text class="pt-6">
            O Arquivo PDF não pode ser maior do que 30MB
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="closeDialogAlertFileSize"
            >
              FECHAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
        v-model="alertBottom.visible"
        :multi-line="true"
    >
      {{ alertBottom.textAlert }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="alertBottom.visible = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Topo from '../components/Topo';
import axios from "axios";
import {ENTRYPOINT} from "../config/entryPointApi";
import latinize from 'latinize';
// ----------------- Documentação do Componente Upload --> https://element.eleme.io/#/en-US/component/upload
import 'element-ui/lib/theme-chalk/index.css';
import Upload from 'element-ui/packages/upload';

function forceDownloadFile(blob, fileName) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob);
    return;
  }
  const data = window.URL.createObjectURL(blob);
  var link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 100);
}

export default {
  name: "Apostilas.vue",
  components: {Topo, Upload},
  data() {
    return {
      somentePDF: {
        checkbox: false
      },
      downloadBtn: {
        loading: false
      },
      alertBottom: {
        visible: false,
        textAlert: null
      },
      detachPdf: {
        loading: false
      },
      alertFileSize: {
        show: false
      },
      textPdf: {
        text: null,
        visible: false
      },
      dialog: {
        loading: false
      },
      upload: {
        headers: {
          'api-token': 'b6fb46b43b032f2de5bb1ed372314f6d',
          'id': null
        },
        //action: 'http://localhost/products/upload',
        action: 'https://api.phpmysql.com.br/products/upload', // Descomentar em produção
        classAdd: '',
        fileList: []
      },
      dataTable: {
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          {
            text: 'ID WebStore',
            value: 'id_webstore'
          },
          {
            text: 'SKU',
            value: 'sku'
          },
          {
            text: 'Apostila',
            value: 'title'
          },
          {
            text: 'Tem PDF',
            value: 'with_pdf_attached',
            filterable: false,
            sortable: false,
            align: 'center'
          },
          {
            text: 'Ativo',
            value: 'available'
          },
          {
            text: 'Search',
            value: 'search',
            align: ' d-none',
            sortable: false,
          },
          {
            align: 'center',
            text: 'Actions',
            value: 'actions',
            sortable: false
          }
        ],
        data: [],
        searchApostila: null,
        loading: false,
        idRowActive: null,
        photoApostila: null,
        dialogEdit: {
          visible: false,
          textTop: null,
          vimg: false
        }
      },
      vueExLocalState: {
        user_email: this.$store.state.user_email,
        user_token: this.$store.state.user_token,
      }

    }
  },
  methods: {
    clickCheckBoxSomenteComPDF() {
      this.callAjaxGetProductsCollection();
    },
    clickDownloadBtn() {
      var productId = this.dataTable.idRowActive;
      this.downloadBtn.loading = true;
      this.callAjaxDownloadById(productId);
    },
    closeDialogAlertFileSize() {
      this.upload.classAdd = '';
      this.alertFileSize.show = false
    },
    clickDetachPdf() {
      var productId = this.dataTable.idRowActive;
      this.detachPdf.loading = true;
      this.callAjaxDetachPdfById(productId);
    },
    uploadRemove() {
      this.upload.classAdd = '';
    },
    uploadComplete(response) {

      this.upload.classAdd = '';

      var data = response.response;

      if (!data.success) {
        this.alertBottom.textAlert = data.message;
        this.alertBottom.visible = true;
      }

      if (data.success) {

        var productId = this.dataTable.idRowActive;
        var indexArrayData = this.dataTable.data.findIndex(x => x.id === productId);
        this.dataTable.data[indexArrayData].with_pdf_attached = 'yes';

        this.textPdf.text = data.data.pathPdf;
        this.textPdf.visible = true;

      }

      this.$refs.uploadControl.clearFiles();

    },
    uploadOnProgress(event, file) {

      var progressPercent = event.percent;
      var sizeFile = event.total;

      if (sizeFile > 60000000) { // Arquivo PDF não pode ter mais do que 30MB
        this.alertFileSize.show = true;
        this.$refs.uploadControl.abort(file);
        this.$refs.uploadControl.clearFiles();
        this.upload.classAdd = '';
      }

      if (progressPercent > 0 && progressPercent < 100 && this.upload.classAdd === '') {
        this.upload.classAdd = 'hide_upload_box';
      }

    },
    openDialog(item) {
      this.dataTable.dialogEdit.vimg = true;
      this.dataTable.dialogEdit.textTop = item.title + '<br>SKU: ' + item.sku;
      this.dataTable.photoApostila = item.picture_url;
      this.dataTable.dialogEdit.visible = true;
      this.dialog.loading = true;
      this.callAjaxGetProductById(item.id);
    },
    closeDialog() {
      this.dataTable.dialogEdit.vimg = false;
      this.dataTable.dialogEdit.visible = false
      this.dataTable.dialogEdit.textTop = null;
      this.dataTable.photoApostila = null;
      this.setClassRow(this.dataTable.idRowActive, false);
      this.textPdf.visible = false;
      this.$refs.uploadControl.abort();
      this.$refs.uploadControl.clearFiles();
      this.upload.classAdd = '';
    },
    setClassRow(id, action, classe) {
      if (action) {
        this.dataTable.idRowActive = id;
        this.$refs['linha' + id].classList.value = classe;
      } else {
        this.dataTable.idRowActive = null;
        this.$refs['linha' + id].classList.value = '';
      }
    },
    editItem(item) {
      this.setClassRow(item.id, true, 'light-blue lighten-4');
      this.openDialog(item);
    },
    callAjaxDownloadById(id) {

      var nameFile = null;

      // var uniqueId = 'id-' + Math.random().toString(16).slice(2);
      nameFile = this.textPdf.text;
      nameFile = latinize(nameFile);
      nameFile = nameFile.replaceAll('-', ' ');
      nameFile = nameFile.replaceAll('/', '-');
      nameFile = nameFile.replaceAll(' ', '-');
      nameFile = nameFile.replaceAll('--', '-');
      nameFile = nameFile.replaceAll('--', '-');
      nameFile = nameFile.toLowerCase();

      return axios({
        url: ENTRYPOINT + `/products/${id}/download?timestamp=${new Date().getTime()}`,
        method: 'get',
        responseType: 'blob',
        headers: {'api-token': this.vueExLocalState.user_token}
      }).then((response) => {

        if (response.data.type === 'application/json') {
          this.alertBottom.textAlert = 'Falha ao tentar realizar o download';
          this.alertBottom.visible = true;
          this.downloadBtn.loading = false;
          return false;
        }

        const content = response.headers['content-type'];
        let blob = new Blob([response.data], {type: content});
        forceDownloadFile(blob, nameFile);
        this.downloadBtn.loading = false;

      }).catch((error) => {

        console.log('error');
        console.log(error);
        this.downloadBtn.loading = false;

      });

    },
    callAjaxDetachPdfById(id) {

      return axios({
        url: ENTRYPOINT + `/products/${id}/detach?timestamp=${new Date().getTime()}`,
        method: 'delete',
        headers: {'api-token': this.vueExLocalState.user_token}
      }).then((response) => {

        let data = response.data.response;

        if (!data.success) { // Quando falha o retorno dos dados

        }

        if (data.success) { // Quando os dados são retornados com êxito
          var indexArrayData = this.dataTable.data.findIndex(x => x.id === id);
          this.dataTable.data[indexArrayData].with_pdf_attached = 'no';
          this.textPdf.text = '';
          this.textPdf.visible = false;
        }

        this.detachPdf.loading = false;

      });

    },
    callAjaxGetProductById(id) {

      return axios({
        url: ENTRYPOINT + `/products/${id}?timestamp=${new Date().getTime()}`,
        method: 'get',
        headers: {'api-token': this.vueExLocalState.user_token}
      }).then((response) => {

        let data = response.data.response;

        if (!data.success) { // Quando falha o retorno dos dados

        }

        if (data.success) { // Quando os dados são retornados com êxito

          var pathPdf = data.data.pathPdf;

          this.upload.headers.id = id;

          if (pathPdf !== null) {
            this.textPdf.text = pathPdf;
            this.textPdf.visible = true;
          }

        }

        this.dialog.loading = false;

      });

    },
    callAjaxGetProductsCollection() {

      this.dataTable.loading = true;

      return axios({
        url: ENTRYPOINT + `/products/getcollection?timestamp=${new Date().getTime()}`,
        method: 'get',
        headers: {'api-token': this.vueExLocalState.user_token},
        params: {
          onlypdf: this.somentePDF.checkbox
        }
      }).then((response) => {

        let data = response.data.response;

        if (!data.success) { // Quando falha o retorno dos dados

        }

        if (data.success) { // Quando os dados são retornados com êxito

          data.data.forEach((teste, index) => {
            data.data[index].search = latinize(teste.title)
          });

          this.dataTable.data = data.data;

        }

        this.dataTable.loading = false;

      });

    }
  },
  created() {
    document.title = 'Adm PDF Apostilas - Apostilas Domínio';
  }
  ,
  mounted() {
    this.callAjaxGetProductsCollection();
  }
}
</script>

<style>

.v-application ul, .v-application ol {
  padding-left: unset;
}

.el-upload-dragger {
  width: 256px;
  height: 110px;
}

.el-upload-dragger .el-icon-upload {
  margin: 9px 0 16px;
}

.my_img {
  max-width: 100% !important;
}

.my_toolbar, .my_toolbar .v-toolbar__content {
  height: unset !important;
}

.text-start, .v-select__selection, .v-data-footer {
  font-size: 16px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 16px !important;
}

.hide_upload_box .el-upload-dragger {
  display: none;
}


/* SM */
@media (min-width: 600px) {
  .my_img {
    max-width: 200px !important;
  }

}

/* MD */
@media (min-width: 960px) {

}

/* LG */
@media (min-width: 1264px) {

}

/* XL */
@media (min-width: 1904px) {

}


</style>