<template>
  <v-card
      class="pa-0 pa-sm-5 fill-height"
      color="blue-grey lighten-5"
      tile
  >

    <Topo
        title="Selecione uma Opção">
    </Topo>

    <v-card-text class="grey lighten-4">
      <v-card
          elevation="1"
          width="256"
      >
        <v-navigation-drawer
            floating
            permanent
        >
          <v-list
              rounded
          >
            <v-list-item
                v-for="item in items"
                :key="item.id"
                link
                @click="clickOption(item.id)"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </v-card-text>

  </v-card>
</template>

<script>
import Topo from '../components/Topo';

export default {
  name: "Menu.vue",
  components: {Topo},
  data() {
    return {
      items: [
        {
          id: 1,
          title: 'PDF Apostilas',
          icon: 'mdi-book-open-variant'
        }
      ]
    }
  },
  methods: {
    clickOption(id) {
      switch (id) {
        case 1: // Link PDF Apostilas
          this.$router.push({name: 'Apostilas'});
          break;
        default:
          break;
      }
    }
  },
  created() {
    document.title = 'Menu Admin - Apostilas Domínio';
  }
}
</script>

<style scoped>

</style>