<template>
  <v-card
      rounded
      elevation="0"
  >
    <v-system-bar></v-system-bar>
    <v-toolbar flat>
      <v-toolbar-title>Olá {{ vueExLocalState.user_name }}</v-toolbar-title>
      <v-spacer></v-spacer>

        <div>
          <v-btn
              text
              color="deep-purple accent-4"
              @click="logOutLocal"
          >
            Sair
          </v-btn>
        </div>


    </v-toolbar>
    <div
        class="pl-1 d-block d-sm-flex flex-row justify-space-between"
    >
      <div class="align-center d-flex col-12 col-sm-6">
      {{ title }}
      </div>
      <div
          v-if="retornar"
          class="mr-sm-4 text-right" style="margin-top: 5px;">
        <v-btn
            text
            color="deep-purple accent-4"
            @click="retMenu"
        >
          Retornar Menu
        </v-btn>
      </div>
    </div>


  </v-card>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "Topo",
  props: {
    retornar: {
      require: true,
      default: false
    },
    title: {
      require: true,
      default: null
    }
  },
  data() {
    return {
      vueExLocalState: {
        user_name: this.$store.state.user_name,
      }
    }
  },
  methods: {
    ...mapMutations([
      'logOut'
    ]),
    logOutLocal() {
      this.logOut();
      this.$router.push({name: 'Login'});
    },
    retMenu() {
      this.$router.push({name: 'Menu'});
    }
  }
}
</script>

<style scoped>

</style>